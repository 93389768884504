import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { BrowserView, MobileView } from "react-device-detect";

import solanaIcon from './svg/solana-icon.svg';
import solanaHorizontal from './svg/solana-horizontal.svg';
import twitterIcon from './svg/twitter-icon.svg';
import discordIcon from './svg/discord-icon.svg';
import animatedCollection from './svg/gif.gif';

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { WalletDisconnectButton } from "@solana/wallet-adapter-material-ui";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

const HeaderContainer = styled.div`
  display: flex;
  height: 100px;
  background-color: black;
  text-align: center;
  flex-direction: row;
  position: fixed;
  border-bottom: 1px solid gray;
  justify-content: space-evenly;
  width: 100%;
  `;

const ConnectButton = styled.div`
  display: flex;
  flex-direction: row;
  height: 40px;
  width: 170px;
  align-items: center;
  justify-content: center;
  background: -webkit-linear-gradient(#015aee , #ffffff);
  border-radius: 20px;
  margin-right: 20px;`;

const ConnectButton_2 = styled(WalletDialogButton)`
  height: 38px;
  width: 168px;
  color: black;`;

const DisconnectButton = styled(WalletDisconnectButton)`
  height: 38px;
  width: 168px;
  color: black;`;

const NameHeader = styled.p`
font: 26px bold Airelon Regular, sans-serif;
margin-left: 10px;  
background: -webkit-linear-gradient(#015aee , #ffffff);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;`;

const AccountContainer = styled.div`
  height: 85px;
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  background: -webkit-linear-gradient(#015aee , #ffffff);
  border-radius: 20px;`;

const AccountContainer_2 = styled.div`
  height: 83px;
  width: 178px;
  display: flex;
  text-align: left;
  background-color: black;
  border-radius: 20px;`;

  
const MintContainer = styled.div`
  display: flex;
  height: 531px;
  flex-direction: row;
  background-color: black;
  justify-content: center;
  align-items: center;
  `;

const MintSide = styled.div`
  display: flex;
  flex:1;
  width: 100%;
  height: 531px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  `;

const GifSide = styled.div`
  display: flex;
  width: 100%;
  flex:1;
  height: 531px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  `;

const MobileMintContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: black;
  justify-content: center;
  align-items: center;
  height: 90vh;
  `;

const MintInformation = styled.div`
  display: flex;
  height: 180px;
  width: 350px;
  box-shadow: rgba(1, 90, 238, 0.4) 0px 5px, rgba(1, 90, 238, 0.3) 0px 10px, rgba(1, 90, 238, 0.2) 0px 15px, rgba(1, 90, 238, 0.1) 0px 20px, rgba(1, 90, 238, 0.05) 0px 25px;
  background: -webkit-linear-gradient(#ffffff , #015aee);
  border-radius: 20px;
  align-items: center;
  justify-content: center;`;

  const MintInformation_2 = styled.div`
  display: flex;
  flex-direction: column;
  height: 70px;
  border-radius: 19px;
  background-color: black; 
  text-align: left;
  width: 99.5%;
  height: 99%;
  align-items; center;
  justify-items: center;`;

const MintButton = styled.div`
  display: flex;
  flex-direction: row;
  background: -webkit-linear-gradient(#015aee , #ffffff);
  height: 40px;
  align-items: center;
  justify-content: center;
  width: 150px;
  border-radius: 20px;
  `;

const MintButton_2 = styled(Button)`
  width: 98.8%;`;

const ImageContainer = styled.div`
  `;

const SocialButton = styled(Button)`
  background: white;
  width: 24px;
  heigth: 24px;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 1;`;

const RoadMapHeader = styled.p`
  font: 48px bold Airelon Regular, sans-serif;
  margin-left: 10px;
  background: -webkit-linear-gradient(#015aee , #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  `;
const RoadMapHeader_2 = styled.p`
  font: 48px bold Airelon Regular, sans-serif;
  margin-left: 10px;
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  `;
const RoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 500px;
  background-color: black;
  justify-content: center;
  align-items: center;
  `;

const MobileRoadmapContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 120vh;
  background-color: black;
  justify-content: center;
  align-items: center;
  `;

const Fonts = styled.p`
  font: 26px bold Belleza, sans-serif;
  color: white;
`;

const QuotesContainer = styled.div`
  display: flex;
  background: black;
  heigth: 1000px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

const MobileQuotesContainer = styled.div`
  display: flex;
  background: black;
  heigth: 400vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

const FaqContainer = styled.div`
  display: flex;
  background: black;
  heigth: 1000px;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  width: 100%;
  `;

const AccordionBorder = styled.div`
  display: flex;
  flex-direction: column;
  background: #015aee;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  width: 76%;
  height: 60px;
  margin-top: 5px;
  margin-bottom: 20px;`;

const AccordionContentDiv = styled.div`
  background-color: black;
  border-radius: 15px;
  border: 1px solid black;
  width: 76%;
  margin-bottom: 10px;
  height: 100px;`;


const BottomContainer = styled.div`
  display: flex;
  background: black;
  justify-content: center;
  align-items: center;
  text-align: center;`;

const GIF = styled.img`
  display: block;
  margin-top: 40px;
  box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 
    0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset;`;

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}


const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [availableItems, setAvailable] = useState(0);
  const [reedemedItems, setRedeemed] = useState(0);
  const [width, setWindowWidth] = useState(0);

  const mintRef = React.useRef<HTMLInputElement>(null);
  const roadRef = React.useRef<HTMLInputElement>(null);
  const faqRef = React.useRef<HTMLInputElement>(null);

  const scrollToMint = () => {
    if (mintRef.current) {
      mintRef.current.scrollIntoView({behavior: "smooth"});
    }
  }

  const scrollToRoadmap = () => {
    if (roadRef.current) {
      roadRef.current.scrollIntoView({behavior: "smooth"});
    }
  }

  const scrollToFaq = () => {
    if (faqRef.current) {
      faqRef.current.scrollIntoView({behavior: "smooth"});
    }
  }

  const accordionData = [
    {
      title: 'How many pieces?',
      content: `Collection 1, titled 'Attraction' launched with 20 pieces. Collection 2, titled 'Mesh', will launch with 80 pieces. Details on further collections will be released shortly.`
    },
    {
      title: 'When is the launch?',
      content: `Sunday 7th November at 11am UTC`
    },
    {
      title: 'Secondary markets?',
      content: `We're currently listed on Magic Eden & Digital Eyes and will get our collections live on Exchange Art after the launch of Collection 2.`
    }
  ];

  interface AccordionProps {
    title: React.ReactNode
    content: React.ReactNode
  }

  const Accordion : React.FC<AccordionProps> = ({ title, content }) => {
    
    const [isAccordionActive, setAccordionActive] = useState(false);
    
    return (
      
      <div style={{width: width, display: "flex", flexDirection: "column", justifyItems: "center", alignItems: "center"}}>
   
          <AccordionBorder className="accordion-title" onClick={() => setAccordionActive(!isAccordionActive)} style={{flexDirection : "row", display: "flex", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontFamily: "Belleza"}}><Fonts style={{fontSize: 20, marginLeft: 25}}>{title}</Fonts></div>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", fontFamily: "Belleza"}}><Fonts style={{fontSize: 24, marginRight: 25}}>{isAccordionActive ? '-' : '+'}</Fonts></div>
          </AccordionBorder>
          
          {isAccordionActive && <AccordionContentDiv style={{textAlign: "left"}} className="accordion-content"><Fonts style={{fontSize: 16 , marginLeft: 20, color: "#7A7A7A"}}>{content}</Fonts></AccordionContentDiv>}
      </div>
    );
  };

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const wallet = useWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet.connected && candyMachine?.program && wallet.publicKey) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet?.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet?.publicKey) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      const anchorWallet = {
        publicKey: wallet.publicKey,
        signAllTransactions: wallet.signAllTransactions,
        signTransaction: wallet.signTransaction,
      } as anchor.Wallet;

      const { candyMachine, itemsRemaining, itemsAvailable, itemsRedeemed } =
        await getCandyMachineState(
          anchorWallet,
          props.candyMachineId,
          props.connection,
        );
      
      setRedeemed(itemsRedeemed);
      setAvailable(itemsAvailable );
      setIsSoldOut(itemsRemaining === 0);
      //setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  }, [wallet, props.candyMachineId, props.connection]);
  
  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <>
      <BrowserView viewClassName="browser-class">

        <HeaderContainer>

            <div style={{flex: 2, flexDirection: "column", display: "flex", justifyContent: "center", alignItems: "center"}}>

              <div style={{flexDirection: "row", display: "flex"}}>
                <NameHeader >Drip Meta</NameHeader>
              </div>

            </div>

            <div style={{flex: 5, flexDirection: "row", display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
              <div onClick={() => scrollToMint()}><Fonts style={{fontSize: 16}}>Mint</Fonts></div>
              <div onClick={() => scrollToRoadmap()}><Fonts style={{fontSize: 16}}>About</Fonts></div>
              <div onClick={() => scrollToFaq()}><Fonts style={{fontSize: 16}}>FAQ</Fonts></div>
            </div>

            {wallet.connected ?
            <div style={{ flex: 1.2,display: "flex",textAlign: "right", justifyContent: "center", alignItems: "center"}}>  
              <ConnectButton>
                  <DisconnectButton style={{borderRadius: 20, backgroundColor: "black"}}>
                    <Fonts style={{fontSize: 14}}>Disconnect</Fonts>
                  </DisconnectButton>
              </ConnectButton>
            </div>
            :
            <div style={{ flex: 1.2,display: "flex",textAlign: "right", justifyContent: "center", alignItems: "center"}}>
                <ConnectButton>
                  <ConnectButton_2 style={{borderRadius: 20, backgroundColor: "black"}}>
                    <Fonts style={{fontSize: 14}}>Connect Wallet</Fonts>
                  </ConnectButton_2>
                </ConnectButton>
            </div>
            }

            <div style={{flex: 1, flexDirection: "column", display: "flex", justifyContent: "center"}}>
              
              <div>
                <SocialButton onClick={(e) => {
                  window.open("https://twitter.com/dripmetanft", "_blank")
                }}>
                  <img className="twitterButton" src={twitterIcon} 
                    alt="Twitter" width="24" height="24"/>
                </SocialButton>
              </div>

              <div>
                <SocialButton onClick={(e) => {
                  window.open("https://discord.gg/yJT86Q4EGn", "_blank")
                }}>
                  <img className="discordButton" src={discordIcon} 
                    alt="Discord" width="24" height="24"/>
                </SocialButton>
              </div>
              
            </div>

        </HeaderContainer>

        <div style={{height: 110, backgroundColor: "black"}} ref={mintRef}></div>

        <MintContainer>

          <GifSide>

            {width > 845 ? 

            <GIF className="car" src={animatedCollection} alt="Collection" width="420" height="420" style={{borderRadius: 15}}></GIF>

            :

            <GIF className="car" src={animatedCollection} alt="Collection" width="280" height="280" style={{borderRadius: 15}}></GIF>
            }
          </GifSide>


          <MintSide>

            <div style={{alignItems: "center", width: width/2.5, textAlign: "left", height: 80, marginTop: 50, flexDirection: "row", display: "flex"}}>
              <RoadMapHeader_2>DRIP</RoadMapHeader_2>
              <RoadMapHeader>META</RoadMapHeader>
            </div>

            <div style={{width: "88%", alignItems: "flex-start", flexDirection: "column",marginLeft: 50, textAlign: "left", display: "flex", flex: 1}}>
              <Fonts style={{fontSize: 18, height: 25}}>We aim to deliver quality art and beautiful pieces. The project focuses on timeless, delicate, exceptional works with quality at the forefront.</Fonts>
              <Fonts style={{fontSize: 18}}>We will express our passion across the arts. Visual, literary, performing arts & the distinctions within. Combining art across the spectrum to deliver masterful pieces that will inspire, educate and excite.</Fonts>
            </div>

            <div style={{display: "flex", flex: 2, justifyContent: "center", width:"100%"}}>
              {wallet.connected ? 
              <MintInformation>
                <MintInformation_2 style={{justifyContent: "center", alignContent: "center"}}>

                  <div style={{display: "flex", justifyContent: "center", height: 50}}>
                    <Fonts style={{fontSize: 20, color: "white", fontWeight: "lighter"}}>{reedemedItems}/{availableItems}</Fonts>
                  </div>

                  <div style={{display: "flex", justifyContent: "center", height: 75}}>
                    <Fonts style={{fontSize: 20, color: "white", fontWeight: "lighter"}}>Price: 0.5 <img src={solanaIcon} alt="Solana Icon" width= "15" height="15" style={{marginLeft: 5}}/></Fonts>
                  </div>

                  <div style={{display: "flex", height: 75, alignContent: "center", justifyContent: "center"}}>  
                    <MintButton>
                      <MintButton_2
                        disabled={isSoldOut || isMinting || !isActive}
                        onClick={onMint}
                        variant="contained"
                        style={{backgroundColor: "black", borderRadius: 20, height: 38}}
                      >
                        {isSoldOut ? (
                          <Fonts style={{color: "white", fontSize: 16}}>SOLD OUT</Fonts>
                        ) : isActive ? (
                          isMinting ? (
                            <CircularProgress />
                          ) : (
                            <Fonts style={{color: "white", fontSize: 16}}>MINT</Fonts>
                          )
                        ) : (
                          <Countdown
                            date={1636285500000}
                            onTick={renderCounter}
                            onMount={({ completed }) => completed && setIsActive(true)}
                            onComplete={() => setIsActive(true)}
                            renderer={renderCounter}
                          />
                        )}
                      </MintButton_2>
                    </MintButton>
                  </div>
                </MintInformation_2>
              </MintInformation>
              
              :

              <MintInformation>
                <MintInformation_2 style={{justifyContent: "center", alignContent :"center"}}>
                  <div style={{display: "flex",flexDirection: "row", justifyContent: "center", height: 50}}>
                
                    <Fonts style={{fontSize: 20, fontWeight: "lighter", color: "white"}}>Please Unlock Wallet</Fonts>
                  
                  </div>

                  <div style={{display: "flex", justifyContent: "center", height: 40}}>
                    <Fonts style={{fontSize: 20, fontWeight: "lighter", color: "white"}}>Price: 0.5 <img src={solanaIcon} alt="Solana Icon" width= "15" height="15" style={{marginLeft: 5}}/></Fonts>
                  </div>

                  <div style={{display: "flex", height: 75, justifyContent: "center", alignItems: "center"}}>
                    <MintButton>
                      <MintButton_2
                          style={{backgroundColor: "black", borderRadius: 20, height: 38}}
                      >
                      {isSoldOut ? 
                        <Fonts style={{color: "white", fontSize: 16}}>SOLD OUT</Fonts>
                        :
                        <Countdown
                        date={1636285500000}
                        onTick={renderCounter}
                        onMount={({ completed }) => completed && setIsActive(true)}
                        onComplete={() => setIsActive(true)}
                        renderer={renderCounter}
                        />
                      }  
                      </MintButton_2>
                    </MintButton>
                  </div>
                </MintInformation_2>
              </MintInformation>
              }

            </div>

          </MintSide>

        </MintContainer>

        <div style={{height: 60, backgroundColor: "black"}} ref={roadRef}></div>

        <RoadmapContainer>

          <div style={{flexDirection: "row", display: "flex", textAlign: "left", width: "81%"}}>
            <RoadMapHeader>About</RoadMapHeader>
          </div>

          <div style={{width: "80%"}}>

            <Fonts style={{fontSize: 20}}>We aim to bring a focus to the art! To build a community of art lovers with exquisite taste, looking for quality, alluring pieces of art. </Fonts>

            <Fonts style={{fontSize: 20}}>Our initial launch made the use of auctions, which allowed art lovers to carefully select the pieces they loved. With art being subjective, it makes sense that where possible, each piece belongs to the one who truly loves it.</Fonts> 

            <Fonts style={{fontSize: 20}}>We will use a combination of auctions, drops where you'll be able to mint a piece you want and blind mints for the excitement factor!</Fonts>

            <Fonts style={{fontSize: 20}}>Our first collection 'Attraction', will be followed by our second collection 'Mesh'. Details our futher collections will be released shortly.</Fonts>

            <Fonts style={{fontSize: 20}}>Moving beyond our collections, through collaborations, we aim to showcase & empower talented artists around the world. Thank you for joining us on this journey.</Fonts>
          
          </div>

        </RoadmapContainer>

        <QuotesContainer>
          
          <div style={{textAlign: "left", display: "flex", width: "81%"}}>
            <RoadMapHeader_2>Be</RoadMapHeader_2>
            <RoadMapHeader>Inspired</RoadMapHeader>
          </div>

          <div style={{width: "80%", display: "flex", flexDirection: "column"}}>
          
            <Fonts style={{fontSize: 19,color : "white", width: "100%", height: 1}}>“Art is science made clear.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Jean Cocteau</Fonts>
            
            <Fonts style={{fontSize: 20,color : "white", width: "100%", height: 1}}>“Creativity takes courage.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Henri Matisse</Fonts>

            <Fonts style={{fontSize: 20,color : "white", width: "100%", height: 1}}>“A picture is a poem without words.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Horace</Fonts>
            
            <Fonts style={{fontSize: 20,color : "white", width: "100%", height: 1}}>“There is no must in art because art is free.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Wassily Kandinsky</Fonts>

            <Fonts style={{fontSize: 20,color : "white", width: "100%", height: 1, }}>“We are what we repeatedly do. Excellence, then, is not an act, but a habit.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Aristotle</Fonts>

          </div>
        </QuotesContainer>

        <div style={{height: 10, backgroundColor: "black"}} ref={faqRef}></div>
        
        <FaqContainer>
        
          <div style={{width: "81%", textAlign: "left", marginTop: 20}}>
            <RoadMapHeader>FAQ</RoadMapHeader>
          </div>

          <div className="accordion">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>
        
        </FaqContainer>
    
        <BottomContainer style={{height: 75}}>
            
          <SocialButton onClick={(e) => {
            window.open("https://twitter.com/dripmetanft", "_blank")
          }}>
            <img className="twitterButton" src={twitterIcon} 
              alt="Twitter" width="24" height="24"/>
          </SocialButton>
          
          <SocialButton onClick={(e) => {
            window.open("https://discord.gg/yJT86Q4EGn", "_blank")
          }}>
            <img className="discordButton" src={discordIcon} 
              alt="Discord" width="24" height="24"/>
          </SocialButton>

          <img src={solanaHorizontal} alt="Solana Horizontal"  width="95.04" height="11.52" style={{marginLeft: 10}}/>
        
        </BottomContainer>

        <Snackbar
          open={alertState.open}
          autoHideDuration={6000}
          onClose={() => setAlertState({ ...alertState, open: false })}
        >
          <Alert
            onClose={() => setAlertState({ ...alertState, open: false })}
            severity={alertState.severity}
          >
            {alertState.message}
          </Alert>
        </Snackbar>
      </BrowserView>
    
      <MobileView viewClassName="mobile-class">
        
        <HeaderContainer>
          
          <div style={{flex: 6.7, flexDirection: "row", display: "flex", marginTop: 12}}>
            <NameHeader style={{fontSize: 22, marginTop: 35}}>Drip Meta</NameHeader>
          </div>

          <div style={{flex: 0.15, flexDirection: "column", alignContent: 'center'}}>
            
            <div style={{marginTop: 25}}>
              <SocialButton style={{marginRight: 10}} onClick={(e) => {
                window.open("https://twitter.com/dripmetanft", "_blank")
              }}>
                <img className="twitterButton" src={twitterIcon} 
                  alt="Twitter" width="24" height="24"/>
              </SocialButton>
            </div>

            <div>
              <SocialButton style={{marginRight: 10}} onClick={(e) => {
                window.open("https://discord.gg/yJT86Q4EGn", "_blank")
              }}>
                <img className="discordButton" src={discordIcon} 
                  alt="Discord" width="24" height="24"/>
              </SocialButton>
            </div>
            
          </div>

        </HeaderContainer>

        <div style={{height: 120, backgroundColor: "black"}}>

        </div>

        <MobileMintContainer>
          
          <ImageContainer>
            <GIF className="car" src={animatedCollection} alt="Collection" width="196" height="196" style={{borderRadius: 15}}></GIF>
          </ImageContainer>

          <MintInformation style={{width: "70%", marginLeft: 0, marginTop: 15, height: 180}}>
            
            <MintInformation_2>

              <div style={{display: "flex", justifyContent: "center", height: 75}}>
                
                <p style={{fontSize: 18, fontWeight: "bold", color: "white", marginTop: "13%"}}>Price: 0.5 <img src={solanaIcon} alt="Solana Icon" width= "15" height="15" style={{marginLeft: 5}}/></p>
              
              </div>

              {wallet.connected ? 
              <MintButton>
                <MintButton_2
                  disabled={isSoldOut || isMinting || !isActive}
                  onClick={onMint}
                  variant="contained"
                  style={{backgroundColor: "black", borderRadius: 20, height: 38}}
                >
                  {isSoldOut ? (
                    <Fonts style={{color: "white", fontSize: 16}}>SOLD OUT</Fonts>
                  ) : isActive ? (
                    isMinting ? (
                      <CircularProgress />
                    ) : (
                      <Fonts style={{color: "white", fontSize: 16}}>MINT</Fonts>
                    )
                  ) : (
                    <Countdown
                      date={1636282800000}
                      onTick={renderCounter}
                      onMount={({ completed }) => completed && setIsActive(true)}
                      onComplete={() => setIsActive(true)}
                      renderer={renderCounter}
                    />
                  )}
                </MintButton_2>
              </MintButton>
              : 
              <ConnectButton>
                <ConnectButton_2 style={{borderRadius: 20, backgroundColor: "black"}}>
                  <Fonts style={{fontSize: 14}}>Connect Wallet</Fonts>
                </ConnectButton_2>
              </ConnectButton>
              }

            </MintInformation_2>

          </MintInformation>
        
        </MobileMintContainer>

        <MobileRoadmapContainer>

          <div style={{flexDirection: "row", display: "flex", textAlign: "left", width: "81%"}}>
            <RoadMapHeader>About</RoadMapHeader>
          </div>

          <div style={{width: "80%"}}>

            <Fonts style={{fontSize: 18}}>We aim to bring a focus to the art! To build a community of art lovers with exquisite taste, looking for quality, alluring pieces of art.</Fonts> 

            <Fonts style={{fontSize: 18}}>  Our initial launch made the use of auctions, which allowed art lovers to carefully select the pieces they loved. With art being subjective, it makes sense that where possible, each piece belongs to the one who truly loves it. </Fonts>

            <Fonts style={{fontSize: 18}}>  We will use a combination of auctions, drops where you'll be able to mint a piece you want and blind mints for the excitement factor!</Fonts>

            <Fonts style={{fontSize: 18}}>  Our first collection 'Attraction', will be followed by our second collection 'Mesh'. Details our futher collections will be released shortly.</Fonts>

            <Fonts style={{fontSize: 18}}>  Moving beyond our collections, through collaborations, we aim to showcase & empower talented artists around the world. Thank you for joining us on this journey.</Fonts>

          </div>

        </MobileRoadmapContainer>

        <MobileQuotesContainer>
          
          <div style={{textAlign: "left", display: "flex", width: "81%"}}>
            <RoadMapHeader_2>Be</RoadMapHeader_2>
            <RoadMapHeader>Inspired</RoadMapHeader>
          </div>

          <div style={{width: "80%", display: "flex", flexDirection: "column"}}>
          
            <Fonts style={{fontSize: 19,color : "white", width: "100%"}}>“Art is science made clear.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Jean Cocteau</Fonts>
            
            <Fonts style={{fontSize: 20,color : "white", width: "100%"}}>“Creativity takes courage.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Henri Matisse</Fonts>

            <Fonts style={{fontSize: 20,color : "white", width: "100%"}}>“A picture is a poem without words.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Horace</Fonts>
            
            <Fonts style={{fontSize: 20,color : "white", width: "100%"}}>“There is no must in art because art is free.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Wassily Kandinsky</Fonts>

            <Fonts style={{fontSize: 20,color : "white", width: "100%"}}>“We are what we repeatedly do. Excellence, then, is not an act, but a habit.”</Fonts>
            <Fonts style={{color : "#CFCFCF", width: "100%", fontSize: 18, fontStyle: "italic"}}>Aristotle</Fonts>

          </div>
        </MobileQuotesContainer>

        <FaqContainer style={{justifyContent: "flex-start", height: 515}}>

          <div style={{width: "81%", textAlign: "left", marginTop: 20}}>
            <RoadMapHeader>FAQ</RoadMapHeader>
          </div>

          <div className="accordion">
            {accordionData.map(({ title, content }) => (
              <Accordion title={title} content={content} />
            ))}
          </div>

        </FaqContainer>

        <BottomContainer style={{height: 75}}>
              
            <SocialButton onClick={(e) => {
              window.open("https://twitter.com/dripmetanft", "_blank")
            }}>
              <img className="twitterButton" src={twitterIcon} 
                alt="Twitter" width="24" height="24"/>
            </SocialButton>
            
            <SocialButton onClick={(e) => {
              window.open("https://discord.gg/yJT86Q4EGn", "_blank")
            }}>
              <img className="discordButton" src={discordIcon} 
                alt="Discord" width="24" height="24"/>
            </SocialButton>

            <img src={solanaHorizontal} alt="Solana Horizontal"  width="95.04" height="11.52" style={{marginLeft: 10}}/>
          
          </BottomContainer>
        
      </MobileView>
    </>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <Fonts style={{fontSize: 16}}>
      {String(days).padStart(2, '0')}:{String(hours).padStart(2, '0')}:{String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
    </Fonts>
  );
};

export default Home;
